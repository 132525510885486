import { render, staticRenderFns } from "./UiAutocomplete.vue?vue&type=template&id=19a38e5a&scoped=true"
import script from "./UiAutocomplete.vue?vue&type=script&lang=js"
export * from "./UiAutocomplete.vue?vue&type=script&lang=js"
import style0 from "./UiAutocomplete.vue?vue&type=style&index=0&id=19a38e5a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a38e5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@4/components/ui/UiIcon.vue').default,UiSpinnerCircle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@4/components/ui/UiSpinnerCircle.vue').default})
